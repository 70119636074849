import React, { useCallback, useState } from "react";
import "./app.css";

import RepositoryUrlInput from "./repositoryInput";
import RecentRepositories from "./recentRepositories";

const AntiCodeApp: React.FC = () => {
    const [refreshKey, setRefreshKey] = useState<number>(0);

    const refreshRecent = useCallback(() => {
        setRefreshKey((key) => key + 1);
    }, []);

    return (
        <div className="AppMain">
            <header className="AppHeader">Browse repos | Scanner queue | Add your repo</header>
            <section className="AppSection">
                <RepositoryUrlInput onRepoUpdated={refreshRecent} />
            </section>
            <section className="AppSection">
                <RecentRepositories refreshKey={refreshKey} />
            </section>
            <section className="AppSection">
                <p>In progress: bla bla 80%</p>
            </section>
            <section className="AppSection">
                <p>Scanner queue:</p>
                <ul>
                    <li>one</li>
                    <li>two</li>
                </ul>
            </section>
        </div>
    );
};

export default AntiCodeApp;
