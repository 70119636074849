import React, { useEffect, useState } from "react";
import { RepositorySchema, fetchRecent, deleteRepository } from "./apiClient";
import { Stack, Typography, List, ListItem, ListItemButton, IconButton } from "@mui/material";
import { Backspace } from "@mui/icons-material";

interface RecentRepositoriesProps {
    refreshKey: number;
}

const RecentRepositories: React.FC<RecentRepositoriesProps> = ({ refreshKey }) => {
    const [repositories, setRepositories] = useState<RepositorySchema[]>([]);
    const [status, setStatus] = useState<string>("ready");
    const [message, setMessage] = useState<string>("");

    useEffect(() => {
        refreshRepositories();
    }, [refreshKey]);

    const refreshRepositories = () => {
        setStatus("busy");
        setMessage("..loading..");
        fetchRecent()
            .then((data) => {
                setRepositories(data);
                setStatus("ready");
                setMessage("");
            })
            .catch((error) => {
                setStatus("failed");
                setMessage(error.message);
            });
    };

    const removeRepository = (url: string) => {
        setStatus("busy");
        setMessage("..loading..");
        deleteRepository(url)
            .then(() => refreshRepositories())
            .catch((error) => {
                setStatus("failed");
                setMessage(error.message);
            });
    };

    return (
        <Stack direction="column" width="100%">
            <Typography id="recent-label" variant="subtitle1">
                Recent repos
            </Typography>

            <List aria-labelledby="recent-label" dense={true}>
                {repositories.map((repo) => (
                    <ListItem
                        key={repo.url}
                        secondaryAction={
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                size="small"
                                onClick={() => removeRepository(repo.url)}
                            >
                                <Backspace />
                            </IconButton>
                        }
                    >
                        <ListItemButton>{repo.url}</ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Typography variant="subtitle2" color={status === "failed" ? "error" : "gray"}>
                &nbsp;{message}&nbsp;
            </Typography>
        </Stack>
    );
};

export default RecentRepositories;
