const API_HOST: string = process.env.REACT_APP_API_HOST || "http://localhost";

export interface RepositorySchema {
    url: string;
}

export interface ScanResponse {
    updated: boolean;
    url: string;
}

export const scanRepository = async (repoUrl: string): Promise<ScanResponse> => {
    const encodedUrl = encodeURIComponent(repoUrl);
    const apiUrl = `${API_HOST}/api/inspector/scan?url=${encodedUrl}`;
    const response = await fetch(apiUrl);

    if (!response.ok) {
        throw new Error("Cannot scan repository, reason: " + response.statusText);
    }

    const data: ScanResponse = await response.json();
    return data;
};

export const deleteRepository = async (repoUrl: string): Promise<void> => {
    const encodedUrl = encodeURIComponent(repoUrl);
    const apiUrl = `${API_HOST}/api/inspector/repo?url=${encodedUrl}`;
    const response = await fetch(apiUrl, { method: "DELETE" });
    if (!response.ok) {
        throw new Error("Cannot delete repository, reason: " + response.statusText);
    }
};

export const fetchRecent = async (): Promise<RepositorySchema[]> => {
    const apiUrl = `${API_HOST}/api/inspector/recent`;
    const response = await fetch(apiUrl);

    if (!response.ok) {
        throw new Error("Cannot get recent repos, reason: " + response.statusText);
    }

    const data: RepositorySchema[] = await response.json();
    return data;
};
