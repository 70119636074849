import React, { useState } from "react";
import { Button, TextField, Typography, Stack } from "@mui/material";
import AddchartIcon from "@mui/icons-material/Addchart";

import { scanRepository } from "./apiClient";

interface RepositoryInputProps {
    onRepoUpdated: () => void;
}

const RepositoryUrlInput: React.FC<RepositoryInputProps> = ({ onRepoUpdated }) => {
    const [url, setUrl] = useState<string>("");
    const [status, setStatus] = useState<string>("ready");
    const [message, setMessage] = useState<string>("");

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value);
        if (event.target.value && !event.target.value.startsWith("https://")) {
            setStatus("failed");
            setMessage("Please provide a valid https url");
        } else {
            setStatus("ready");
            setMessage("");
        }
    };

    const onAddRepository = (repoUrl: string) => {
        setStatus("busy");
        scanRepository(repoUrl)
            .then((repo) => {
                setUrl("");
                setMessage("");
                setStatus("fading");
                setTimeout(() => setStatus("ready"), 5000);
                if (repo.updated) onRepoUpdated();
            })
            .catch((error) => {
                console.error(error);
                setStatus("failed");
                setMessage(error.message);
            });
    };

    return (
        <Stack direction="column" width="100%">
            <Typography variant="subtitle2">Add your repository</Typography>
            <Stack direction="row" alignItems="inherit" width="100%" spacing={1}>
                <TextField
                    required={true}
                    fullWidth={true}
                    label="Repository URL"
                    value={url}
                    onChange={onChange}
                    error={status === "failed"}
                    helperText={message}
                    style={{ flexGrow: 4 }}
                    variant="outlined"
                    disabled={status === "busy"}
                />
                <Button
                    variant="contained"
                    onClick={() => onAddRepository(url)}
                    disabled={url === "" || status === "busy" || status === "failed"}
                >
                    <AddchartIcon fontSize="large" />
                </Button>
            </Stack>
            {status === "fading" ? (
                <Typography className="fading" color="lightgreen">
                    OK
                </Typography>
            ) : (
                <Typography color="lightgreen">&nbsp;</Typography>
            )}
        </Stack>
    );
};

export default RepositoryUrlInput;
